import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from '../Pages/LoginPage';

const UnauthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <LoginPage />
      </Route>
      <Route path="/login" exact>
        <LoginPage />
      </Route>

      <Redirect to="/" />
    </Switch>
  );
};

export default UnauthenticatedRoutes;
