import StorageConstants from '../Constants/StorageConstants';
import { format, parse, isAfter } from 'date-fns';
import StorageUtils from 'src/Utils/StorageUtils';
import jwtDecode from 'jwt-decode';

function getHeader(token: string): {} {
  try {
    const data = token.split('.')[0];
    return JSON.parse(atob(data));
  } catch (e) {
    throw new Error(e);
  }
}

function getToken(): string | undefined {
  const local = window.localStorage.getItem(StorageConstants.AUTH);
  if (local) {
    const auth = JSON.parse(local);
    return auth.token;
  }
}

function setAuth(token: string): void {
  try {
    const data: any = jwtDecode(token);

    const expiresIn = format(parse(data.exp, 't', new Date()), 'yyyy-MM-dd HH:mm:ss');

    data.expiresIn = expiresIn;
    data.isValidExpirationDate = isAfter(new Date(expiresIn), new Date());

    window.localStorage.setItem(StorageConstants.AUTH, JSON.stringify({ token, ...data }));
  } catch (e) {
    throw new Error('Invalid jwt');
  }
}

function getAuth<T>(): T | undefined {
  const item = window.localStorage.getItem(StorageConstants.AUTH);
  if (item) {
    return JSON.parse(item);
  }
}

function cleanToken(): void {
  window.localStorage.removeItem(StorageConstants.AUTH);
}

function isExpirationDateValid() {
  try {
    const token = getToken() || '';
    const data: any = jwtDecode(token);
    const exp = parse(data.exp, 't', new Date());
    return isAfter(exp, new Date());
  } catch (e) {
    console.error(e);
  }
}

function logout() {
  StorageUtils.removeItem(StorageConstants.AUTH);
  window.location.href = String(process.env.REACT_APP_LOGOUT_SSO_URL);
}

export default {
  setAuth,
  getAuth,
  getToken,
  cleanToken,
  getHeader,
  isExpirationDateValid,
  logout,
};
