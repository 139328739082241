import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Styles from './styles';

const Theme = ({ theme = Styles, children }: any) => {
  const GlobalStyle = createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      outline: none;
      box-sizing: border-box;
    }
    
    html, body {
      width: 100vw;
      height: 100vh;
      overflow-x: hidden;
    }
    
    body {
      background-color: #F9F9F9;
      font-family: 'Nunito', sans-serif;
    }
  `;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
