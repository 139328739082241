import styled from 'styled-components';
import Row from 'src/Components/Row';

const IconWrapper = styled(Row)`
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')}
`;

IconWrapper.defaultProps = {
  width: '30px',
  height: '30px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '50%',
};

export default IconWrapper;
