import styled from 'styled-components';
import {
  compose,
  variant,
  typography,
  TypographyProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  textAlign,
  TextAlignProps,
  textShadow,
  TextShadowProps,
  textStyle,
  TextStyleProps,
} from 'styled-system';

type TextProps = { variant?: string; textTransform?: string } & TypographyProps &
  SpaceProps &
  ColorProps &
  TextAlignProps &
  TextShadowProps &
  TextStyleProps;

const BIGEST = 'bigest';
const GIANT = 'giant';
const BIGGER = 'bigger';
const BIG = 'big';
const REGULAR = 'regular';
const SMALL = 'small';
const SMALLER = 'smaller';

const Text = styled.span<TextProps>`
  ${compose(
    variant({
      variants: {
        [BIGEST]: {
          fontSize: 24,
        },
        [GIANT]: {
          fontSize: 22,
        },
        [BIGGER]: {
          fontSize: 18,
        },
        [BIG]: {
          fontSize: 16,
        },
        [REGULAR]: {
          fontSize: 14,
        },
        [SMALL]: {
          fontSize: 12,
        },
        [SMALLER]: {
          fontSize: 10,
        },
      },
    }),
    space,
    color,
    typography,
    textAlign,
    textShadow,
    textStyle
  )}
  ${(props) => (props.textTransform ? `text-transform: ${props.textTransform};` : '')}
`;

Text.defaultProps = {
  variant: REGULAR,
};

export default Text;
