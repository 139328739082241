import React from 'react';
import Theme from './Themes';
import Routes from './Routes';

export default function App() {
  return (
    <Theme>
      <Routes />
    </Theme>
  );
}
