import React, { useState, useContext } from 'react';
import { ContextInterface, defaultContextValue } from 'src/Utils/ContextApiUtils';
import AuthUtils from 'src/Utils/AuthUtils';

export interface AuthContextInterface {
  token: string;
  nbf: number;
  exp: number;
  iss: string;
  aud: string[];
  client_id: string;
  sub: string;
  auth_time: number;
  idp: string;
  TenantId: string;
  UserId: string;
  Username: string;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string;
  scope: string[];
  amr: string[];
  expiresIn: string;
  isValidExpirationDate: boolean;
}

type ContextType = ContextInterface<Partial<AuthContextInterface> | undefined>;

const AuthContext = React.createContext<ContextType>(defaultContextValue);

function useAuthContext(): ContextType {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuthContext must be used within a AuthProvider');

  return context;
}

function AuthProvider(props: any) {
  const [data, setData] = useState<Partial<AuthContextInterface> | undefined>(AuthUtils.getAuth());

  if (data?.token) {
    AuthUtils.setAuth(data.token);
  }

  return <AuthContext.Provider value={{ data, setData }} {...props} />;
}

export { AuthProvider, useAuthContext };
