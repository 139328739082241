import React from 'react';
import Row from 'src/Components/Row';
import Text from 'src/Components/Text';

interface DividerTitleProps {
  title: string;
}

export default function DividerTitle({ title }: DividerTitleProps) {
  return (
    <Row pb="16px" borderBottom="1px solid" borderColor="primary">
      <Text variant="bigger" fontWeight="bold" color="primary" textTransform="capitalize">
        {title}
      </Text>
    </Row>
  );
}
