import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import DashboardPage from '../Pages/DashboardPage';
import LogoutPage from '../Pages/LogoutPage';

const AuthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <DashboardPage />
      </Route>
      <Route path="/dashboard" exact>
        <DashboardPage />
      </Route>
      <Route path="/logout" exact>
        <LogoutPage />
      </Route>

      <Redirect to="/" />
    </Switch>
  );
};

export default AuthenticatedRoutes;
