function setItem(key: string, value: any): void {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function getItem(key: string): {} | undefined {
  const item = window.localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
}

function removeItem(key: string): void {
  window.localStorage.removeItem(key);
}

export default {
  setItem,
  getItem,
  removeItem,
};
