import React from 'react';
import Row from 'src/Components/Row';
import logo from 'src/Assets/Images/logo.svg';
import { Logo } from './styles';

export default function Brand() {
  return (
    <Row px="10px" height="100%" alignItems="center">
      <Logo src={logo} />
    </Row>
  );
}
