import apisauce from 'apisauce';
import VideosServices from './VideosServices';

const api = apisauce.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});

api.addResponseTransform((response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(JSON.stringify(response.data, null, 2));
  }
});

const Services = {
  videos: VideosServices.create({ api }),
};

export default Services;
