import React, { useState, useEffect, useCallback } from 'react';
import Navbar from 'src/Components/Navbar';
import Text from 'src/Components/Text';
import Column from 'src/Components/Column';
import { useAuthContext } from 'src/Contexts/AuthContext';
import VideoCard from 'src/Components/VideoCard';
import Services from 'src/Services';
import { VideosInterface } from 'src/Services/VideosServices';
import VideoUtils, { GroupedVideosInterface } from 'src/Utils/VideoUtils';
import DividerTitle from 'src/Components/DividerTitle';

export default function DashboardPage() {
  const authContext = useAuthContext();
  const [videos, setVideos] = useState<GroupedVideosInterface[]>([]);

  const getAllVideos = useCallback(async () => {
    const responseData = await Services.videos.getAllVideos();
    if (responseData.status === 200 && responseData.data) {
      const sortedVideos = VideoUtils.sortVideosByDate(responseData.data.data);
      const groupedVideos = VideoUtils.groupVideosByDate(sortedVideos);
      setVideos(groupedVideos);
    }
  }, []);

  useEffect(() => {
    getAllVideos();
  }, [getAllVideos]);

  return (
    <>
      <Navbar />
      <Column maxWidth="1200px" margin="46px auto 0 auto" px="20px">
        <Column pb="40px">
          <Text variant="bigest" fontWeight="bold" color="primary">
            Bem-vindo(a) {authContext.data?.Username}
          </Text>
          <Text variant="bigger" color="secondary">
            Veja os últimos vídeos dos nossos eventos!
          </Text>
        </Column>
        {(videos || []).map((groupedVideoItem: GroupedVideosInterface, groupedVideoIndex: number) => (
          <Column key={groupedVideoIndex} pt="10px">
            <DividerTitle title={groupedVideoItem.date} />
            {groupedVideoItem.videos.map((video: VideosInterface, index: number) => (
              <VideoCard key={index} {...video} />
            ))}
          </Column>
        ))}
      </Column>
    </>
  );
}
