import React from 'react';
import Column from 'src/Components/Column';
import Text from 'src/Components/Text';
import { VimeoContainer } from './styles';
import { useMediaQuery } from 'react-responsive';

interface VideoCardProps {
  title: string;
  videoUrl: string;
}

export default function VideoCard({ title, videoUrl }: VideoCardProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });

  return (
    <Column px={isMobile ? '0px' : '50px'} py="40px">
      <VimeoContainer>
        <iframe
          title={title}
          src={videoUrl}
          width="100%"
          height="600"
          frameBorder="0"
          allow="fullscreen"
          allowFullScreen
        />
      </VimeoContainer>
      <Text variant="regular" fontSize="28px" fontWeight="bold" color="primary" pt="20px">
        {title}
      </Text>
    </Column>
  );
}
