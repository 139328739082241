import React from 'react';
import Row from 'src/Components/Row';
import Text from 'src/Components/Text';
import { FaUserAlt } from 'react-icons/fa';
import { RiLoginBoxLine } from 'react-icons/ri';
import IconWrapper from 'src/Components/IconWrapper';
import { useMediaQuery } from 'react-responsive';

interface NavbarMenuProps {
  userName?: string | undefined;
  logoutOnClick?: () => void;
}

export default function NavbarMenu({ userName, logoutOnClick }: NavbarMenuProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });

  return (
    <Row px="10px" height="100%" alignItems="center" justifyContent="center">
      {!isMobile && (
        <>
          {!!userName && (
            <>
              <IconWrapper mr="10px" backgroundColor="#BBBBBB">
                <FaUserAlt color="#ffffff" />
              </IconWrapper>
              <Text color="#ffffff">{userName}</Text>
            </>
          )}
        </>
      )}
      {!!logoutOnClick && (
        <IconWrapper ml="5px" backgroundColor="transparent" onClick={logoutOnClick}>
          <RiLoginBoxLine size={20} color="#ffffff" />
        </IconWrapper>
      )}
    </Row>
  );
}
