import React, { useCallback } from 'react';
import Row from 'src/Components/Row';
import Brand from 'src/Components/Brand';
import NavbarMenu from 'src/Components/NavbarMenu';
import AuthUtils from 'src/Utils/AuthUtils';
import { useAuthContext } from 'src/Contexts/AuthContext';

export default function Navbar() {
  const authContext = useAuthContext();

  const handleLogoutButton = useCallback(() => {
    AuthUtils.cleanToken();
    window.location.href = String(process.env.REACT_APP_LOGOUT_SSO_URL);
  }, []);

  return (
    <Row width="100%" height="54px" backgroundColor="primary" boxShadow="0px 2px 4px #00000033">
      <Row width="100%" maxWidth="1200px" px="20px" margin="0 auto" alignItems="center" justifyContent="space-between">
        <Brand />
        <NavbarMenu userName={authContext.data?.Username} logoutOnClick={handleLogoutButton} />
      </Row>
    </Row>
  );
}
