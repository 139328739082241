import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
  border,
  BorderProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  boxShadow,
  BoxShadowProps,
} from 'styled-system';

export type RowProps = FlexboxProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  BoxShadowProps;

const Row = styled.div<RowProps>(flexbox, space, layout, color, border, position, shadow, boxShadow);

Row.defaultProps = {
  display: 'flex',
  flexDirection: 'row',
};

export default Row;
