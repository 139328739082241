import format from 'date-fns/format';
import locale from 'date-fns/locale/pt';
import { compareDesc } from 'date-fns';
import { VideosInterface } from 'src/Services/VideosServices';

export interface GroupedVideosInterface {
  date: string;
  videos: VideosInterface[];
}

function sortVideosByDate(videos: VideosInterface[]) {
  return videos.sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
}

function groupVideosByDate(videos: VideosInterface[]) {
  return videos.reduce((previous: GroupedVideosInterface[] | any[], current: VideosInterface) => {
    const groupedVideos: GroupedVideosInterface[] = previous || [];

    const formattedDate = format(new Date(current.date), 'dd/MM/yyyy - EEEE', { locale });

    if (!groupedVideos.find((groupedVideoItem) => groupedVideoItem.date === formattedDate)) {
      groupedVideos.push({ date: formattedDate, videos: [] });
    }

    groupedVideos[groupedVideos.length - 1].videos.push(current);

    return groupedVideos;
  }, []);
}

export default {
  sortVideosByDate,
  groupVideosByDate,
};
