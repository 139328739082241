import { ApisauceInstance, ApiResponse } from 'apisauce';

export interface VideosInterface {
  title: string;
  videoUrl: string;
  date: string;
}

export interface Response<T> {
  data: T[];
}

const create = ({ api }: { api: ApisauceInstance }) => {
  const getAllVideos = (): Promise<ApiResponse<Response<VideosInterface>>> => {
    api.setBaseURL('');
    return api.get(String(process.env.REACT_APP_AWS_S3_VIDEOS_MOCK));
  };

  return {
    getAllVideos,
  };
};

export default { create };
