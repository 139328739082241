import React, { useCallback, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuthContext, AuthContextInterface } from 'src/Contexts/AuthContext';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import WindowUtils from 'src/Utils/WindowUtils';
import AuthUtils from 'src/Utils/AuthUtils';

const Routes = () => {
  const authContext = useAuthContext();

  const checkToken = useCallback(() => {
    const authStored = AuthUtils.getAuth<AuthContextInterface>();
    const accessToken = WindowUtils.getQueryParam('accessToken');

    if (accessToken) {
      if (!authStored || accessToken !== authContext.data?.token) {
        authContext.setData({ token: accessToken });
      }

      window.location.href = '/';
    } else {
      if (!authStored || (authStored && !AuthUtils.isExpirationDateValid())) {
        AuthUtils.logout();
      }
    }
  }, [authContext]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return <Router>{authContext.data?.token ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}</Router>;
};

export default Routes;
