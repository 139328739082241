function getQueryParam(name: string): string | null {
  let value = null;

  if (window.location.search) {
    const url = new URLSearchParams(window.location.search);
    if (url) {
      value = url.get(name);
    }
  }

  return value;
}

export default {
  getQueryParam,
};
